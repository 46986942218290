import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, computed, watchEffect,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

// eslint-disable-next-line import/prefer-default-export
export default function commercialDetails() {
  const location = ref({
    full: null,
    streetNumber: null,
    city: null,
    state: null,
    country: null,
    postalCode: null,
  })

  const donationCategories = ['Zakah', 'Fitr', 'Sadakah', 'other']

  const paymentDetailsData = ref({
    paymentType: [
      { value: null, text: 'Please select option' },
      { value: 'a', text: 'Credit Card' },
      { value: 'b', text: 'Bank Account' },
    ],
    selectedPaymentType: null,
    businessName: null,
    legalName: null,
    transitNumber: null,
    institutionNumber: null,
    accountNumber: null,
    charitableNumber: null,
    incorporationNumber: null,
    accountHolderName: null,
    accountHolderPosition: null,
    accountHolderdofb: null,
  })

  const commercialDetailsData = ref({
    name: null,
    lat: '43.85327',
    lng: '-79.429509',
    logo: null,
    cover: null,
    about: null,
    phone_number: null,
    email: null,
    social_facebook: null,
    social_twitter: null,
    social_instagram: null,
    social_youtube: null,
    website: null,
    class_id: null,
    sub_class_id: null,
    street: null,
    city: null,
    state: null,
    country: null,
    postal_code: null,
    service_name: null,
    service_description: null,
    language_id: null,
    tag_id: null,
    payment_type: null,
    business_type: null,
    business_name: null,
    legal_name: null,
    account_holder_name: null,
    account_holder_position: null,
    account_holder_dofb: null,
    transit_number: null,
    institution_number: null,
    account_number: null,
    phoneNum: null,
    charitable_number: null,
    donation_cats_id: null,
    id: router.currentRoute.params.id,
  })

  const getLang = () => store.dispatch('commercialModule/getLanguages')
  getLang()

  const languages = computed(() => store.state.commercialModule.languages)

  const getTags = () => store.dispatch('commercialModule/getTags')
  getTags()

  const tags = computed(() => store.state.commercialModule.tags)

  const getDC = () => store.dispatch('commercialModule/getDonationCategories')
  getDC()

  const getClass = () => store.dispatch('commercialModule/getClassification')
  getClass()

  const classifications = computed(() => store.state.commercialModule.classifications)

  // const getSubClass = computed(() => store.state.commercialModule.subclassifications)

  const DC = computed(() => store.state.commercialModule.donationCategories)

  const fetchD = () => store.dispatch('commercialModule/fetchINPODetail', { id: router.currentRoute.params.id })
  fetchD()
  const fetD = computed(() => store.state.commercialModule.editInpo)

  return {
    location,
    donationCategories,
    paymentDetailsData,
    commercialDetailsData,
    languages,
    tags,
    DC,
    fetchD,
    fetD,
    classifications,
  }
}
