<template>
  <div>
    <b-row>
      <!-- {{ commercialDetails.postal_code }}
      {{ commercialDetails.name }} -->
      <b-col md="6">
        <!-- Name -->
        <validation-provider
          #default="validationContext"
          name="Name"
          rules="required"
        >
          <b-form-group
            label="Name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="commercialDetails.name"
              autofocus
              :state="getValidationState(validationContext)"
              trim
              placeholder="Name"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Mobile Number -->
      <b-col md="6">
        <validation-provider
          #default="{ errors }"
          rules="required|min:8"
          name="Mobile Number"
        >
          <b-form-group
            label="Mobile Number"
            label-for="mobile-number"
          >
            <b-form-input
              id="mobile-number"
              v-model="commercialDetails.phone_number"
              :state="errors.length > 0 ? false:null"
            />

            <b-form-invalid-feedback>
              {{ errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Email -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Email"
          rules="required|email"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="commercialDetails.email"
              :state="getValidationState(validationContext)"
              trim
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- About -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="About"
        >
          <b-form-group
            label="About"
            label-for="about"
          >
            <b-form-textarea
              id="about"
              v-model="commercialDetails.about"
              :state="getValidationState(validationContext)"
              placeholder="About"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Classification -->
      <b-col cols="6">
        <validation-provider
          #default="validationContext"
          name="Classification"
        >
          <b-form-group
            label="Classification"
            label-for="classification"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="commercialDetails.classification"
              label="name"
              :options="typeClassifications"
              @input="passEvent"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- subClassification -->
      <b-col cols="6">
        <validation-provider
          #default="validationContext"
          name="subClassification"
        >
          <b-form-group
            label="Sub Classification"
            label-for="sub-classification"
            :state="getValidationState(validationContext)"
          >
            <v-select
              v-model="commercialDetails.subClassifications"
              label="name"
              :reduce="tag => tag.id"
              :options="subClassifications"
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Location -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="Location"
        >
          <b-form-group
            label="Location"
            label-for="location"
          >
            <input
              id="location"
              ref="reflocation"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Type Your Location"
              class="form-control"
            >
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Street -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="street"
        >
          <b-form-group
            label="Street"
            label-for="street"
          >
            <b-form-input
              id="street"
              v-model="commercialDetails.address[0].street"
              :state="getValidationState(validationContext)"
              placeholder="Street"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- City -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="city"
        >
          <b-form-group
            label="City"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="commercialDetails.address[0].city"
              :state="getValidationState(validationContext)"
              trim
              placeholder="City"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- State -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="state"
        >
          <b-form-group
            label="Province / State"
            label-for="state"
          >
            <b-form-input
              id="state"
              v-model="commercialDetails.address[0].state"
              :state="getValidationState(validationContext)"
              trim
              placeholder="State"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Country -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="country"
        >
          <b-form-group
            label="Country"
            label-for="country"
          >
            <b-form-input
              id="country"
              v-model="commercialDetails.address[0].country"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Country"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Postal Code -->
      <b-col md="4">
        <validation-provider
          #default="validationContext"
          name="postalCode"
        >
          <b-form-group
            label="Zip / Postal Code"
            label-for="postalCode"
          >
            <b-form-input
              id="postalCode"
              v-model="commercialDetails.address[0].postal_code"
              :state="getValidationState(validationContext)"
              trim
              placeholder="Zip / Postal Code"
            />
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-col
      cols="12"
      class="pt-1"
    >
      <div class="payment">
        <h5>Commercial Services</h5>
      </div>
    </b-col>

    <b-row
      v-for="(e, index) in commercialDetails.services"
      :key="index"
    >
      <b-col
        md="6"
      >
        <validation-provider
          #default="validationContext"
          name="Title"
        >
          <b-form-group
            label="Title"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="e.name"
              :state="getValidationState(validationContext)"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col
        md="6"
      >
        <validation-provider
          #default="validationContext"
          name="description"
        >
          <b-form-group
            label="Description"
            label-for="description"
          >
            <b-form-textarea
              id="description"
              v-model="e.description"
              :state="getValidationState(validationContext)"
              placeholder="Description"
            />

            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        class="mt-50"
      >
        <b-button
          variant="primary"
          class="mr-1"
          @click="addMulti"
        >
          Add New
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, onUnmounted, reactive,
} from '@vue/composition-api'
import store from '@/store'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BFormGroup, BFormInput, BFormInvalidFeedback, BFormTextarea, BFormSelect, BCol, BRow, BButton,
} from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email } from '@validations'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import commercialLocation from './CommercialLoaction.vue'
import commercialModule from './CommercialModule'
import commercial from './commercial'

Vue.use(VueSweetalert2)

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BFormTextarea,
    ValidationProvider,
    // eslint-disable-next-line vue/no-unused-components
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    BFormSelect,
    // eslint-disable-next-line vue/no-unused-components
    commercialLocation,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
  },
  props: {
    commercialDetails: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
    donationCategories: {
      type: Array,
      default: () => [],
    },
    classifications: {
      type: Array,
      default: () => [],
    },
    subClassifications: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      id: null,
      typeClassifications: [
        {
          id: 2,
          name: 'Business',
        },
        {
          id: 3,
          name: 'Freelancer',
        },
      ],
    }
  },
  computed: {
    stateOptions() {
      return this.items.map(item => item.name)
    },
    districtOptions() {
      return this.form.id ? this.items.find(item => item.name === this.form.id).options : []
    },
  },
  watch: {
    commercialDetails(va) {
      console.log(va)
    },
  },
  created() {
    this.ddd()
  },
  mounted() {
    this.initAutocomplete()
    this.setSelected()
    console.log(this.commercialDetails.street)
    extend('min', value => {
      if (value.length >= 8) {
        return true
      } if (value.length >= 14) {
        return 'Please enter valid Number'
      }
      return 'Please enter valid Number'
    })
  },
  setup() {
    const COMMERCIAL_MODULE = 'commercialModule'

    // Register module
    if (!store.hasModule(COMMERCIAL_MODULE)) store.registerModule(COMMERCIAL_MODULE, commercialModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMMERCIAL_MODULE)) store.unregisterModule(COMMERCIAL_MODULE)
    })

    const {
      addCommercial, locations, reflocation, initAutocomplete, ddd,
    } = commercial()

    const variant = ref('dark')
    const option = ref(['Canada', 'United States'])
    const selected = ref(null)
    const selectedCategory = ref(null)
    const selectedPayment = ref(null)

    const form = ref({
      id: '',
      options: '',
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      variant,
      option,
      selected,
      selectedCategory,
      selectedPayment,
      form,
      addCommercial,
      locations,
      reflocation,
      initAutocomplete,
      ddd,
    }
  },
  methods: {
    passEvent(value) {
      this.$emit('changeTitle', value.id)
    },
    addMulti() {
      this.commercialDetails.service_name.push()
      this.commercialDetails.service_description.push()
    },
    alidateNumber(event) {
      const { keyCode } = event
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault()
      }
    },
    setSelected(value) {
      this.id = value.id
      console.log(value.id)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
