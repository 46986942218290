<template>
  <validation-observer
    ref="infoRules"
    tag="form"
  >
    <b-row>

      <!-- Logo -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Logo"
        >
          <b-form-group
            label="Logo"
            label-for="logo"
            :state="getValidationState(validationContext)"
          >
            <b-form-file
              id="logo"
              v-model="commercialAddLastData.logo"
              accept="image/jpeg, image/png, image/gif"
              placeholder="Choose an image or drop it here..."
              drop-placeholder="Drop image here..."
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Cover -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="Cover"
        >
          <b-form-group
            label="Cover"
            label-for="cover"
            :state="getValidationState(validationContext)"
          >
            <b-form-file
              id="cover"
              v-model="commercialAddLastData.cover"
              accept="image/jpeg, image/png, image/gif"
              placeholder="Choose an image or drop it here..."
              drop-placeholder="Drop image here..."
            />
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>

      <!-- Media List -->
      <b-col md="6">
        <validation-provider
          #default="validationContext"
          name="media"
        >
          <b-form-group
            label="Media List"
            label-for="media"
            :state="getValidationState(validationContext)"
          >
            <b-form-file
              id="media"
              v-model="commercialAddLastData.mediaList"
              multiple
              accept="image/jpeg, image/png, image/gif"
              placeholder="Choose an image or drop it here..."
              drop-placeholder="Drop image here..."
            >
              <template
                slot="file-name"
                slot-scope="{ names }"
              >
                <b-badge variant="dark">
                  {{ names[0] }}
                </b-badge>
                <b-badge
                  v-if="names.length > 1"
                  variant="dark"
                  class="ml-1"
                >
                  + {{ names.length - 1 }} More files
                </b-badge>
              </template>
            </b-form-file>
            <b-form-invalid-feedback :state="getValidationState(validationContext)">
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import {
  // eslint-disable-next-line no-unused-vars
  BForm, BFormGroup, BFormInvalidFeedback, BFormFile, BCol, BRow, BBadge,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { ref } from '@vue/composition-api'
// eslint-disable-next-line no-unused-vars
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BRow,
    BCol,
    BFormFile,
    BFormGroup,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BBadge,
  },
  props: {
    commercialAddLastData: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
