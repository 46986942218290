<template>
  <div>
    <!-- BODY -->
    <form-wizard
      color="#00A5AF"
      :title="null"
      :subtitle="null"
      shape="square"
      finish-button-text="Submit"
      back-button-text="Previous"
      class="mb-3 p-3"
      @on-complete="updateUser()"
    >
      <tab-content
        title="Commercial Details"
      >
        <commercial-details
          ref="reflocations"
          :commercial-details="commData"
          :classifications="classifications"
          :options="options"
          :donation-categories="donationCategories"
          :sub-classifications="getSubClass"
          @changeTitle="ChangeT($event)"
        />
      </tab-content>
      <tab-content
        title="Second"
      >
        <commercial-seconde
          :commercial-details="commData"
          :options="languages"
          :tag-options="tags"
          :donation-categories="DC"
        />
      </tab-content>
      <tab-content
        title="Payment Details"
      >
        <payment-details
          :payment-details-data="commData"
        />
      </tab-content>
      <tab-content
        title="Finish"
      >
        <commercial-add-last
          :commercial-add-last-data="commData"
        />
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  ref, watch, onUnmounted, reactive, computed, watchEffect,
} from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { FormWizard, TabContent } from 'vue-form-wizard'
// eslint-disable-next-line no-unused-vars
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, alphaNum, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import commercial from './commercial'
import commercialDetails from './CommercialDetails.vue'
import commercialSeconde from './CommercialSeconde.vue'
import paymentDetails from './PaymentDetails.vue'
import commercialAddLast from './CommercialAddLast.vue'
import commercialModule from './CommercialModule'

Vue.use(VueSweetalert2)

export default {
  components: {
    FormWizard,
    TabContent,
    commercialDetails,
    commercialSeconde,
    paymentDetails,
    commercialAddLast,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      variant: 'dark',
      typeOptions: [],
      classificationOptions: [],
      option: ['Canada', 'United States'],
      options: ['English', 'Arabic', 'German', 'other'],
      autocomplete: null,
      place: null,
      sub_class: null,
    }
  },
  setup() {
    const COMMERCIAL_MODULE = 'commercialModule'

    // Register module
    if (!store.hasModule(COMMERCIAL_MODULE)) store.registerModule(COMMERCIAL_MODULE, commercialModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COMMERCIAL_MODULE)) store.unregisterModule(COMMERCIAL_MODULE)
    })
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const {
      donationCategories,
      paymentDetailsData,
      commercialDetailsData,
      addCommercial,
      languages,
      tags,
      DC,
      fetD,
      classifications,
    } = commercial()

    const commData = ref({})

    // eslint-disable-next-line camelcase
    const subClassId = ref([])

    const id = ref(null)
    // store.dispatch('commercialModule/getSubClassification', { id: 3 })

    watchEffect(() => store.dispatch('commercialModule/getSubClassification', { id: id.value }))

    const getSubClass = computed(() => store.state.commercialModule.subclassifications)

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      donationCategories,
      paymentDetailsData,
      commercialDetailsData,
      addCommercial,
      languages,
      tags,
      DC,
      fetD,
      classifications,
      getSubClass,
      commData,
      subClassId,
      id,
    }
  },
  created() {
    this.getType()
    this.getClassification()
    this.subClass()
  },
  mounted() {
    this.getData()
    this.initAutocomplete()
  },
  methods: {
    ChangeT(id) {
      this.id = id
    },
    cancel() {
      this.formData = {}
    },
    getType() {
      axios.get(this.typeUrl).then(res => {
        this.typeOptions = res.data.data.data
      })
    },
    getClassification() {
      axios.get(this.classificationUrl).then(res => {
        this.classificationOptions = res.data.data.data
      })
    },
    getData() {
      store.dispatch('commercialModule/getUserInpo', { id: router.currentRoute.params.id }).then(res => {
        this.commData = res.data.data
      })
    },
    updateComm() {
      store.dispatch('commercialModule/addComm', { id: router.currentRoute.params.id }, { userData: this.commData }).then(res => {
        console.log(res.data)
      }).catch(err => console.log(err))
    },
    initAutocomplete() {
      // eslint-disable-next-line no-undef
      this.autocomplete = new google.maps.places.Autocomplete(this.$refs.reflocations.$refs.reflocation, {
        componentRestrictions: { country: ['us', 'ca'] },
        fields: ['address_components', 'geometry'],
        types: ['address'],
      })
      console.log(this.autocomplete)
      this.autocomplete.addListener('place_changed', this.fillInAddress)
    },
    fillInAddress() {
      const place = this.autocomplete.getPlace()
      this.place = place
      console.log(place)
      // eslint-disable-next-line no-restricted-syntax
      for (const component of this.place.address_components) {
        const componentType = component.types[0]
        // eslint-disable-next-line default-case
        switch (componentType) {
          case 'street_number': {
            this.commData.address[0].street = `${component.long_name}, `
            break
          }
          case 'route': {
            this.commData.address[0].street += component.short_name
            break
          }
          case 'postal_code': {
            this.commData.address[0].postal_code = component.long_name
            break
          }
          case 'locality':
            this.commData.address[0].city = component.long_name
            break
          case 'administrative_area_level_1': {
            this.commData.address[0].state = component.short_name
            break
          }
          case 'country':
            this.commData.address[0].country = component.short_name
            break
        }
      }
    },
    updateUser() {
      // eslint-disable-next-line no-unused-vars
      axios.post(`http://entities-microsevice.muslimdo.com/api/internalops/commercial_entity/${router.currentRoute.params.id}?_method=PUT`,
        {
          name: this.commData.name,
          lat: '43.85327',
          lng: '-79.429509',
          logo: this.commData.logo,
          cover: this.commData.cover,
          about: this.commData.about,
          phone_number: this.commData.phone_number,
          email: this.commData.email,
          social_facebook: this.commData.social_facebook,
          social_twitter: this.commData.social_twitter,
          social_instagram: this.commData.social_instagram,
          social_youtube: this.commData.social_youtube,
          website: this.commData.website,
          class_id: this.commData.classification,
          sub_class_id: this.commData.subClassifications,
          street: this.commData.street,
          city: this.commData.city,
          state: this.commData.state,
          country: this.commData.country,
          postal_code: this.commData.postal_code,
          service_name: this.commData.service_name,
          service_description: this.commData.service_description,
          language_id: this.commData.languages,
          tag_id: this.commData.tags,
          payment_type: this.commData.payment_type,
          business_type: this.commData.business_type,
          business_name: this.commData.paymentdetails.business_name,
          legal_name: this.commData.paymentdetails.legal_name,
          account_holder_name: this.commData.paymentdetails.account_holder_name,
          account_holder_position: this.commData.paymentdetails.account_holder_position,
          account_holder_dofb: this.commData.paymentdetails.account_holder_dofb,
          transit_number: this.commData.paymentdetails.transit_number,
          institution_number: this.commData.paymentdetails.institution_number,
          account_number: this.commData.paymentdetails.account_number,
          phoneNum: this.commData.paymentdetails.phoneNum,
          charitable_number: this.commData.paymentdetails.charitable_number,
          donation_cats_id: this.commData.donation_cats_id,
          id: router.currentRoute.params.id,
        }).then(res => {
        console.log(res.data)
        if (res.data.status === 200) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Udpated Successfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
          router.push('/commercial')
          // eslint-disable-next-line no-empty
        } else {}
      }).catch(err => {
        if (err.response.data.errors.name) {
          console.log(err.response.data.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors.name.toString(),
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } if (err.response.data.errors.phone_number) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors.phone_number.toString(),
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        } if (err.response.data.errors.email) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.response.data.errors.email.toString(),
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
      })
    },
    subClass() {
      store.dispatch('commercialModule/SubClassification', this.id).then(res => {
        console.log(res.data)
        this.subClassId = res.data
      }).catch(err => console.log(err))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
